<template>
  <div class="admin__container">
    <h4>Empresas</h4>
    <form @submit.prevent="createCompany">
      <custom-input v-model="company.name" />
      <Button type="submit" size="small" variant="primary"> Crear empresa </Button>
    </form>
    <table>
      <tr>
        <th>Nombre</th>
        <th>id</th>
        <th></th>
      </tr>
      <tr v-for="company in companies" :key="company.id">
        <td>{{ company.name }}</td>
        <td>{{ company.id }}</td>
        <td>
          <Button @click="onDeleteCompany(company.id)" size="small" variant="danger">
            <unicon width="16px" height="16px" name="trash" fill="var(--danger-color-400)"></unicon>
          </Button>
        </td>
      </tr>
    </table>

    <h4>Usuarios</h4>
    <form @submit.prevent="createUser">
      <custom-input v-model="user.name" label="Nombre" />
      <custom-input v-model="user.email" label="Email" />
      <custom-input v-model="user.password" label="Contraseña" />
      <custom-input v-model="user.companyId" label="Empresa id" />
      <Button type="submit" size="small" variant="primary"> Crear usuario </Button>
    </form>
    <table>
      <tr>
        <th>Nombre</th>
        <th>Email</th>
        <th>id</th>
        <th>Company</th>
        <th>Pay</th>
        <th>Admin</th>
        <th></th>
        <th></th>
      </tr>
      <tr v-for="user in users" :key="user.id">
        <td>{{ user.name }}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.id }}</td>
        <td>{{ companies.find(({ id }) => id === user.companyId).name }}</td>
        <td>
          <custom-select
            v-model="user.pay"
            :options="[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]"
            label=""
          />
        </td>
        <td>
          <custom-select
            v-model="user.admin"
            :options="[
              { id: 'true', name: 'true' },
              { id: 'false', name: 'false' },
            ]"
            label=""
          />
        </td>
        <td>
          <Button
            @click="
              giveUserCredentials(user.id, {
                pay: user.pay === 'true',
                admin: user.admin === 'true',
              })
            "
            size="xsmall"
            variant="primary"
          >
            Give
          </Button>
        </td>
        <td>
          <Button @click="onDeleteUser(user.id)" size="xsmall" variant="danger">
            <unicon width="16px" height="16px" name="trash" fill="var(--danger-color-400)"></unicon>
          </Button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CustomSelect from '@/components/custom/CustomSelect.vue';
import CustomInput from '@/components/custom/CustomInput.vue';
import Button from '@/components/buttons/Button.vue';

export default {
  name: 'Admin',
  components: {
    CustomInput,
    Button,
    CustomSelect,
  },
  data() {
    return {
      company: {
        name: '',
      },
      user: {
        name: '',
        email: '',
        password: '',
        companyId: '',
      },
      select: '',
    };
  },

  methods: {
    ...mapActions(['getUserCredentials']),
    ...mapActions('admin', [
      'assignUserCredentials',
      'fetchCompanies',
      'addCompany',
      'deleteCompany',
      'fetchUsers',
      'addUser',
      'deleteUser',
    ]),
    onDeleteCompany(id) {
      const answer = prompt('Ingrese si para eliminar:');
      if (answer === 'si') this.deleteCompany(id);
      else alert(`Su respuesta fue: ${answer}`);
    },
    giveUserCredentials(uid, roles) {
      this.assignUserCredentials({
        uid,
        roles,
      });
    },
    async createCompany() {
      await this.addCompany(this.company);
      this.company = {
        name: '',
      };
    },
    async createUser() {
      await this.addUser(this.user);
      this.user = {
        name: '',
        email: '',
        password: '',
        companyId: '',
      };
    },
    onDeleteUser(id) {
      const answer = prompt('Ingrese si para eliminar:');
      if (answer === 'si') this.deleteUser(id);
      else alert(`Su respuesta fue: ${answer}`);
    },
  },

  computed: {
    ...mapState({
      companies: (state) => state.admin.companies,
      users: (state) => state.admin.users,
    }),
  },

  async mounted() {
    await this.fetchCompanies();
    await this.fetchUsers();
  },
};
</script>

<style lang="scss" scoped>
.admin__container {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
form {
  display: flex;
  flex-flow: column;
  gap: 1rem;
}
</style>
